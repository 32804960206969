<template lang="pug">
  v-col(v-if="lastSnap !== undefined" cols='12' md='8' xl='8')
    h2 Node Snapshot
    br
    v-container
      v-card
        v-card-title(class="text-overline")
          v-row(no-gutters)
            v-col(cols='12' sm='2')
              v-sheet.ma-2.pa-2 HEIGHT
            v-col(cols='12' sm='3')
              v-sheet.ma-2.pa-2 SIZE
            v-col(cols='12' sm='3')
              v-sheet.ma-2.pa-2 AGE
            v-col(cols='12' sm='4')
              v-sheet.ma-2.pa-2 DOWNLOAD
        v-divider
        v-card-test(v-for="snap in latestSnaps" :key="snap.name")
          v-row(no-gutters)
            v-col(cols='12' sm='2')
              v-sheet.ma-2.pa-2 {{snap.height}}
            v-col(cols='12' sm='3')
              v-sheet.ma-2.pa-2 {{formatSize(snap.size)}} GB
            v-col(cols='12' sm='3')
              v-sheet.ma-2.pa-2(v-if="formatDate(snap.date) > 59") {{Math.ceil(formatDate(snap.date) / 60)}} hours ago
              v-sheet.ma-2.pa-2(v-else) {{formatDate(snap.date)}} minutes ago
            v-col(cols='12' sm='4')
              v-sheet.ma-2.pa-2
                a(:href="snap.url") {{snap.name}}

        //- v-card-text(v-if="lastSnap[0] !== undefined")
        //-   v-row(no-gutters)
        //-     v-col(cols='12' sm='3')
        //-       v-sheet.ma-2.pa-2 {{lastSnap[0].height}}
        //-     v-col(cols='12' sm='3')
        //-       v-sheet.ma-2.pa-2 {{lastSnap[0].size}} GB
        //-     v-col(cols='12' sm='3')
        //-       v-sheet.ma-2.pa-2(v-if="lastSnap[0].date > 59") {{Math.ceil(lastSnap[0].date / 60)}} hours ago
        //-       v-sheet.ma-2.pa-2(v-else) {{lastSnap[0].date}} minutes ago
        //-     v-col(cols='12' sm='3')
        //-       v-sheet.ma-2.pa-2
        //-         a(:href="lastSnap[0].url") {{lastSnap[0].name}}
        //- v-card-text(v-else)
        //-   v-row(no-gutters)
        //-     v-col(v-for='s in 4' cols='12' sm='3')
        //-       v-sheet.ma-2.pa-2
        //-         v-progress-circular(color="primary" indeterminate)

    div(v-if="lastSnap[0] !== undefined && latestSnaps.length == 1")
      br
      h2 How to use
      br
      v-list-item-content(align="left")
        v-list-item-title.text-h5.mb-4 Install lz4 if needed
        v-list-item-title.text-p.mb-4
          div
            v-card(color="grey-lighten-4" ounded="0" flat)
              v-toolbar
                v-spacer
                v-btn(elevation=0 @click="copyCommands('commands1')")
                  v-icon mdi-content-copy
              br
              div(ref="commands1")
                p.pl-7 sudo apt update
                p.pl-7.pb-4 sudo apt install lz4
        v-list-item-title.text-h5.mb-4 Download the snapshot
        v-list-item-title.text-p.mb-4
          div
            v-card(color="grey-lighten-4" ounded="0" flat)
              v-toolbar
                v-spacer
                v-btn(elevation=0 @click="copyCommands('commands2')")
                  v-icon mdi-content-copy
              br
              div(ref="commands2")
                p.pl-7.pb-4(v-if="lastSnap[0] !== undefined") wget -O {{lastSnap[0].name}} {{lastSnap[0].url}} --inet4-only
        v-list-item-title.text-h5.mb-8 Stop your node
        v-list-item-title.text-p.mb-4
          div
            v-card(color="grey-lighten-4" ounded="0" flat)
              v-toolbar
                v-spacer
                v-btn(elevation=0 @click="copyCommands('commands3')")
                  v-icon mdi-content-copy
              br
              div(v-if="project.ecosystem === 'Cosmos'" ref="commands3")
                p.pl-7 DB_PATH="YOUR_DB_PATH"
                p.pl-7 SERVICE_NAME="YOUR_SERVICE_NAME"
                p.pl-7 NODE_BINARY_PATH="YOUR_NODE_BINARY_PATH"
                p.pl-7 sudo service $SERVICE_NAME stop
                p.pl-7 # Back up priv_validator_state.json if needed
                p.pl-7 cp $DB_PATH/data/priv_validator_state.json  $DB_PATH/priv_validator_state.json
                p.pl-7 # Reset and restore chain data from snapshot
                p.pl-7 $NODE_BINARY_PATH tendermint unsafe-reset-all --home $DB_PATH --keep-addr-book
                p.pl-7(v-if="lastSnap[0] !== undefined") lz4 -c -d {{lastSnap[0].name}}  | tar -x -C $DB_PATH
                p.pl-7 # Replace with the backed-up priv_validator_state.json
                p.pl-7.pb-4 cp $DB_PATH/priv_validator_state.json  $DB_PATH/data/priv_validator_state.json
              div(v-if="project.ecosystem === 'Substrate'" ref="commands3")
                p.pl-7 DB_PATH="YOUR_DB_PATH"
                p.pl-7 SERVICE_NAME="YOUR_SERVICE_NAME"
                p.pl-7 sudo service $SERVICE_NAME stop
                p.pl-7.pb-4(v-if="lastSnap[0] !== undefined") lz4 -c -d {{lastSnap[0].name}}  | tar -x -C $DB_PATH
        v-list-item-title.text-h5.mb-8 Restart your node
        v-list-item-title.text-p.mb-4
          div
            v-card(color="grey-lighten-4" ounded="0" flat)
              v-toolbar
                v-spacer
                v-btn(elevation=0 @click="copyCommands('commands4')")
                  v-icon mdi-content-copy
              br
              div(ref="commands4")
                p.pl-7 sudo service $SERVICE_NAME start
                p.pl-7(v-if="lastSnap[0] !== undefined") rm -v {{lastSnap[0].name}}
                p.pl-7 sudo service $SERVICE_NAME status
                p.pl-7.pb-4 sudo journalctl -u $SERVICE_NAME -f
</template>

<script lang="ts">
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { State } from 'vuex-class';
import openbitlab from '@/types.d';
import { filter, values } from 'lodash';
import api, { ChainSnapshotData } from '@/api';

@Component({
  components: { },
})
export default class SnapshotService extends Vue {
  @Prop({ required: true }) readonly project!: openbitlab.Project;

  @State('services') services!: openbitlab.RootState['services'];

  private lastSnap: ChainSnapshotData[] = [];

  private latestSnaps: ChainSnapshotData[] = [];

  @Watch('services', { immediate: true, deep: true })
  async updatedProjects(services: openbitlab.RootState['services']) {
    await this.getLastSnapshot();
  }

  // eslint-disable-next-line class-methods-use-this
  public formatSize(size: number) {
    return parseFloat((size / 1024 / 1024 / 1024).toFixed(2));
  }

  // eslint-disable-next-line class-methods-use-this
  public formatDate(date: string) {
    const timeSnap = new Date(date);
    const utcHoursOffset = Math.abs((new Date() as any).getHours() - (new Date() as any).getUTCHours());
    const snapDateOffset = (new Date() as any).getTime() - (timeSnap as any).getTime();
    return Math.ceil(snapDateOffset / 1000 / 60 - (utcHoursOffset * 60)).toString();
  }

  async getLastSnapshot(): Promise<ChainSnapshotData | undefined> {
    const snapSubDomain = filter(values(this.services), (s) => s.chain === this.project.name && s.network === this.project.network && s.type === 'snapshots')[0];
    const chainName = snapSubDomain.url.split('.')[0].split('/')[2].split('-snapshot')[0];
    const snapshots: ChainSnapshotData[] = await api.getChainSnapshotData(chainName, this.project.network);
    this.latestSnaps = filter(values(snapshots), (s) => s.url.indexOf('latest.tar.lz4') !== -1);
    if (this.latestSnaps.length === 0 && snapshots.length > 0) {
      this.latestSnaps = filter(values(snapshots), (s) => s.url.indexOf('tar.lz4') !== -1);
    } else {
      return undefined;
    }

    for (let i = 0; i < this.latestSnaps.length; i += 1) {
      this.latestSnaps[i].size = parseFloat((this.latestSnaps[i].size / 1024 / 1024 / 1024).toFixed(2));
      const timeSnap = new Date(this.latestSnaps[i].date);
      const utcHoursOffset = Math.abs((new Date() as any).getHours() - (new Date() as any).getUTCHours());
      const snapDateOffset = (new Date() as any).getTime() - (timeSnap as any).getTime();
      this.latestSnaps[i].date = Math.ceil(snapDateOffset / 1000 / 60 - (utcHoursOffset * 60)).toString();
    }

    this.lastSnap = [this.latestSnaps[0]];
    return this.lastSnap[0];
  }

  public copyCommands(part: string): void {
    let cmd = '';
    // eslint-disable-next-line
    (this.$refs[part] as any).childNodes.forEach((p: any, index: number) => {
      if (p.textContent.indexOf('#') === -1) {
        cmd = `${cmd}${index === 0 ? '' : '\n'}${p.textContent}`;
      }
    });
    navigator.clipboard.writeText(cmd);
  }
}
</script>

<style lang="scss">
p {
  font-family: "monospace";
}
</style>
